@import "../../../../assets/scss/fonts";
.input {
  label {
    font-size: 14px;
    margin-right: 20px;
    width: 100px;
  }
  display: flex;
  padding: 10px;
}

.icon-edit {
  font-size: $font-size-icon-edit;
}
