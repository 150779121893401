.csv_to_json {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .import {
    margin-bottom: 20px;
  }
  .submit {
    margin-bottom: 20px;
    margin-right: 20px;
  }
}
