//config color
// $color-btn-create: #03fc56;
// $color-card-list-group: #e6473c;
// $color-card-list-group-hover: #c7382e;
// $backgroud-login: whitesmoke;
// $print: black;

//config color
$color-btn-create: #03fc56;
$color-card-list-group: #FB724B;
$color-card-list-group-hover: #FB724B;
$backgroud-login: whitesmoke;
$print: black;

