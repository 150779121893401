@import "../../../assets/scss/fonts";
@import "../../../assets/scss/vars";
.bgr-mobile {
  display: none;
}
.all {
  background-image: url('../../../assets/images/background-login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-color: #ddd;
  padding-top: 50px;
  .form-signIn2 {
    padding: 20px 40px;
    border-radius: 16px;
    width: 450px;
    background-color: #fff;
    margin: auto;
    .header {
      text-align: center;
      img {
        width: 100px;
      }
    }
    #title {
      text-align: center;
      color: $color-card-list-group;
      font-size: 19px;
      line-height: 1.13em;
      padding-bottom: 20px;
      font-weight: bold;
    }
  }
}
.title-home {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  margin: 5%;

  #name {
    text-transform: uppercase;
    font-weight: 700;
  }
  font-size: $font-title;
  font-weight: 600;
}
.contact {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}
.form-signIn {
  display: flex;
  height: 100vh;
  justify-content: flex-end;
  .carousel {
    img {
      margin: 0;
      height: 100vh;
      width: 100%;
    }
  }
  #content {
    background-color: white;
    height: 100vh;
    .header {
      padding: 10px;
      justify-content: center;
      display: flex;
      img {
        width: 100px;
        height: 80px;
      }
      .name_header {
        display: flex;
        font-size: $font-name-title;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .login {
      display: flex;
      justify-content: center;
      height: 78%;
    }
    #small-bg {
      max-width: 100%;
    }
  }
}
#title {
  text-transform: uppercase;
  font-weight: 500;
  div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
}
@media screen and(max-width: 800px) {
  .all{
    .form-signIn2 {
      width: 100%;
      #title {
        font-size: 16px;
      }
    }
  }
  #title {
    font-size: 18px;
  }
  .form-signIn .carousel img {
    height: 30vh;
    object-fit: cover;
  }
  .form-signIn #content {
    height: max-content !important;
    width: 100% !important;
  }
  .contact {
    padding-right: 20px;
  }
}
