.form_show_info {
  > div {
    width: 100%;
    div {
      margin-bottom: 1em;
    }
  }
  display: flex;
  position: relative;
  .qr_code_info {
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
  }
}
