@import "../../../../assets/scss/vars";
@import "../../../../assets/scss/fonts";
.form-register {
  margin: 0 auto;
  #name-form {
    font-size: $font-title;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .register-input {
    display: flex;
    justify-content: center;
    width: 350px;
    input {
      height: 35px;
      border-radius: 5px;
    }
  }
  #submit-btn {
    width: 140px;
    margin-left: 60%;
  }
}
