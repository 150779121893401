.not_found {
  padding-top: 10%;
  display: flex;
  justify-content: center;
  .btn_back {
    text-align: center;
  }
}
@media screen and (max-width: 800px) {
  .not_found {
    img {
      width: 100%;
      padding-top: 25%;
    }
  }
}
