//create function scss
// eg:
@mixin algument($top, $right) {
  margin-top: $top;
  margin-right: $right;
}
@mixin algument-space-between {
  display: flex;
  justify-content: space-between;
}
@mixin algument-center($left, $right) {
  margin-left: $left;
  margin-right: $right;
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin the-form {
  color: white;
  width: 420px;
  margin: auto;
  margin-top: 5vh;
  a {
    color: white;
  }
  p {
    font-size: 65px;
    margin-bottom: 20px;
    text-align: center;
  }
}
@mixin responsive-scroll($max-width) {
  @media only screen and(max-width: $max-width) {
    overflow: scroll;
  }
}
@mixin center() {
  display: flex;
  justify-content: center;
}

@mixin betweenCenter() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin flexEnd {
  display: flex;
  justify-content: flex-end;
}
@mixin border($weight, $color) {
  border: $color solid $weight;
}
@mixin displayOneLine() {
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

@mixin styleIcon() {
  cursor: pointer;
  font-size: 20px;
}
