.check_user {
  div {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
