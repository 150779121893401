@import "../../../../assets/scss/main.scss";
.tutorial {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
}
// .login-form {
//   width: 350px;
// }
.login-form-forgot {
  display: flex;
  justify-content: space-between;
  .register-forgotPass {
    font-size: 15px;
  }
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.name-form {
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: $color-card-list-group;
}
.login-form-button {
  width: 100%;
  height: 40px;
  font-size: 20px;
  border-radius: 5px;
  border: none;
  margin-bottom: 5px;
}
.text-input {
  color: rgba(0, 0, 0, 0.65);
  font-size: 20px;
  height: 50px;
  border-radius: 0px 5px 5px 0px;
  background: rgba(0, 0, 0, 0.1);
  border-left: 2px solid white;
  border-right: none;
  border-top: none;
  border-bottom: none;
  padding: 5px;
  transition: 0.2s;

  &:hover {
    border-left: 12px solid rgba(0, 0, 0, 0.05);
  }

  input {
    font-size: 20px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.65);
    background-color: transparent;
  }
}
// .contact {
//   display: flex;
//   justify-content: flex-end;
//   padding-top: 110px;
//   margin-right: -60px;
// }
@media screen and(max-width: 800px) {
  .contact {
    margin-right: 0;
    padding-top: 0;
  }
}
