.admin-header {
  position: sticky;
  top: 0;
  z-index: 10;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  img {
    cursor: pointer;
    margin: 0;
  }
  &__full-name {
    cursor: pointer;
    font-size: 16px;
  }
  &__text {
    line-height: 1.85em;
  }
  &__title {
    text-transform: capitalize;
    font-size: 20px;
    color: #fb724b;
    font-weight: bold;
  }
  &__name {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
  }
}
