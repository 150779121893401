.title_name {
  color: white;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media only screen and(max-width: 700px) {
  .TimeCreate,
  .Description {
    display: none;
  }
}
@media only screen and(max-width: 500px) {
  .TimeCreate,
  .Description {
    display: none;
  }
}
