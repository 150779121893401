@import '../../assets/scss/fonts';
#navbar-container {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 5px 8px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 0px 5px 8px 0px rgba(50, 50, 50, 0.1);
  height: 72px;
}
.logoName {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  font-size: $font-size-logo;
  font-weight: 600;
  text-transform: uppercase;
  color: black;
  margin-left: 10px;
  img {
    padding-right: 5px;
    width: 80px;
  }
}
#group-search-input {
  margin: 8px;
}
.logoName {
  img {
    margin: 0;
  }
}

@media screen and(max-width: 800px) {
  .logoName {
    font-size: 14px;
  }
}
