.admin-members {
  width: 100%;
  &__search-org {
    margin-bottom: 12px;
  }
  &__organization-label {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
  }
  &__organization-label-member {
    font-weight: bold;
    font-size: 16px;
  }
  &__organization-action{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
}
