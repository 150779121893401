.qr-login {
  background-image: url("../../../../assets/images/cpc1hn.jpg");
  background-size: cover;
  height: 100vh;
  //   display: flex;
  //   justify-content: center;
  h3 {
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
  }
  Form {
    margin: 0 auto;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .form-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 40%;
    background-color: white;
    border-radius: 5%;
  }
}
.link_go_back {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 800px) {
  // .qr-login {
  //   background-position: center;
  // }
  .form-login {
    width: 100% !important;
  }
}
