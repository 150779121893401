@import "../../../../assets/scss/mixins";
.action-col {
  display: flex;
  justify-content: space-start;
}
.action {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 20px;
  &:hover {
    color: mediumturquoise;
  }
}
#download-data-btn {
  color: white;
  background-color: rgb(14, 159, 255);
  border: none;
  border-radius: 2px;
  &:hover {
    background-color: rgb(61, 168, 255);
  }
}
.list-tool {
  margin: 15px;
}
.search_dowload {
  display: flex;
  justify-content: space-between;
}
#member-table {
  @include responsive-scroll(600px);
  .user_email {
    display: none;
  }
}
