@import '../../../../assets/scss/vars';

.btn_parameter {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.btn-create {
  position: fixed;
  bottom: 60px;
  right: 40px;
  z-index: 1000;
}

.width-100 {
  width: 100%;
}

.device-add {
  padding: 20px;

  .btn-add-excel {
    background-color: #67c23a;
    color: #fff;

    &:hover {
      border-color: #67c23a !important;
    }
  }
}
