.admin-member {
  cursor: pointer;
  padding: 12px 0;

  &:hover {
    font-weight: bold;
    background-color: #FEDCD2;
    transition: 200ms ease-out;
  }
  &-selected {
    font-weight: bold;
    background-color: #FEDCD2;
    padding: 12px 8px;
  }
}
.admin-membe-detail__column-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}