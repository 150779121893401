@import "../../assets/scss/fonts";
@import "../../assets/scss/vars";
#header {
  max-width: 180px;
  margin-top: 20px;
  margin-left: 50px;
  border-bottom: 2px solid lightgray;
}
#dashboard-content {
  width: 100vw;
  padding: 20px;
}
.dashboard-card {
  height: 140px;
  width: 140px;
  margin: 5px;
  padding-top: 15px;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;
  background: $color-card-list-group;
  background: linear-gradient(
    135deg,
    $color-card-list-group 0%,
    $color-card-list-group 80%
  );
  color: white;
  transition: 0.5s;
  &:hover {
    padding-top: 20px;
    background: linear-gradient(
      135deg,
      $color-card-list-group-hover 0%,
      $color-card-list-group-hover 60%
    );
  }
}

.dashboard-add-card {
  height: 150px;
  width: 150px;
  margin: 5px;
  padding-top: 15px;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;
}

.user-info-card {
  width: 100%;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  border-radius: 15px;
  padding: 15px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  border: 0.5px solid rgba(50, 50, 50, 0.1);
  .dropdown {
    margin-bottom: 140px;
    font-size: $font-size-icon-edit;
    color: black;
  }
}

.sub-header {
  font-size: 18px;
}
