@import '../../assets/scss/main.scss';
.btn-info-user {
  padding: 30px;
  display: flex;
  .badge {
    align-self: center;
    padding-left: 10px;
    cursor: pointer;
  }
}
.header2 {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: $background-header;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-self: center;
  }
  h1 {
    margin: 0;
    padding-left: 10px;
    border-left: 3px solid #001529;
  }
  .groupName {
    @media screen and (max-width: 767px) {
      font-size: 18px !important;
    }
  }
  .hamburger {
    cursor: pointer;
    align-self: center;
    font-size: 20px;
    padding-right: 5px;
  }
}
