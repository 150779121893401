@import "../../../assets/scss/mixins";
.btn-create-report {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.convert-csv {
  display: flex;
}
.action_report {
  display: flex;
}
.list_report {
  @include responsive-scroll(750px);
}
