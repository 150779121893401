@import "../../assets/scss/mixins";
#sidebar-container {
  width: 250px;
  position: fixed;
  z-index: 9999;
  overflow-y: auto;
}

#sidebar-container-v2 {
  position: fixed;
  z-index: 9999;
  overflow-y: auto;
  display: flex;
  height: 100%;
  // .humburger_sidebar {
  //   display: none;
  // }
}
#close-menu {
  font-size: 18px;
  color: lightgray;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  transition: 0.2s;
  &:hover {
    font-size: 20px;
    color: white;
  }
}
.side_bar {
  @include responsive-scroll(600px);
}
@media screen and (max-width: 767px) {
  #sidebar-container-v2 {
    .humburger_sidebar {
      display: inherit;
      text-align: end;
    }
    // width: 0 !important;
  }
}
