.widthScreen {
  width: 790px;
  .deviceQR {
    width: 115px;
    display: inline-block;
    margin: 20px;
    .child {
      text-align: center;
    }
    .text {
      color: #000;
      text-align: center;
      margin-top: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      height: 40px;
    }
  }
}
