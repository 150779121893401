.admin-layout {
  position: relative;
  height: 100vh;
  &__sidebar-content {
    height: 90vh;
  }
  &__content {
    padding: 30px 20px;
    min-height: 80vh;
  }
}