@import "../../../../assets/scss/main.scss";
.table_lst_device {
  max-width: 100%;
  @include responsive-scroll(950px);
}
.pagi_table {
  @include flexEnd;
  margin: 20px;
}
.search {
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.create {
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
}
.create-search {
  display: flex;
  justify-content: space-between;
}
a {
  color: rgba(0, 0, 0, 0.65);
}
.loading {
  margin-top: 450px;
}
.icon-active {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  .icon-action {
    font-size: $font-size-icon-edit;
    margin: 0 20px;
  }
}
.get-list .ant-table-content {
  overflow: initial !important;
}

@media only screen and(max-width: 900px) {
  .col_timeCreate,
  .col_note {
    display: none;
  }
}
@media only screen and(max-width: 770px) {
  .col_deviceValue,
  .col_referenceCode {
    display: none;
  }
  .maintain_devision {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and(max-width: 450px) {
  .col_location {
    display: none;
  }
}
