@media screen and (max-width: 767px) {
  .sidebar2 {
    width: 0 !important;
    // padding-left: 10px;
  }
}
.header-content {
  height: 100vh;
  overflow: scroll;
}
