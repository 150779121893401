.ant-col-16 {
  max-width: 100% !important;
}
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  height: 100px;
}
.qr_code {
  background-image: url("../../../../assets/images/background-login.jpg");
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-weight: bold;
    margin: 0;
  }
  h3{
    font-weight: bold;
  }
  .report-title {
    padding-bottom: 15px;
  }
  .report-content {
    text-align: center;
    background-color: #ffffff9c;
    border-radius: 16px;
    padding: 20px 10px;
  }

  Button {
    margin: 10px;
  }
}
