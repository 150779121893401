#group-content {
  margin-left: auto;
  margin-right: auto;
}
.header,
.content {
  padding-left: 10px;
}
#menu-trigger {
  color: rgb(0, 153, 255);
  font-size: 22px;
  width: fit-content;
  &:hover {
    color: rgb(86, 213, 255);
  }
}
